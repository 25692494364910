$primary: #284359;
$textPrimary: #284359;
$borderPrimary: #284359;
$dark: #333333;
$light: #F3F3F3;

$apre: #418ccb;
$apre-extralight: #f4fafe;
$apre-light: #afd0e9;
$apre-dark: #284359;
$apre-focus: #7e70b1;
