@import '/src/style/general.scss';

.reactFlow {
    .customNode {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 3px;
        min-width: 150px;
        max-width: 300px;
        border-width: 1px;
        border-style: solid;
        border-color: #b1b1b7;
        background-color: white;
        color: #284359;

        .title {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 5px;
            padding-inline: 3px;
            background-color: $apre-light;
            font-size: 1.1rem;

            > span {
                overflow: hidden;
                word-break: keep-all;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }

        .content {
            width: 100%;
            font-size: 0.8em;
            padding: 5px;
            display: flex;
            flex-direction: column;
            white-space: pre-wrap;
            word-break: break-word;
        }

        .blockList {
            &:not(:first-child) {
                margin-top: 10px;
            }

            > .blockTitle {
                font-size: 1.2em;
                padding-left: 5px;
            }

            > .blockContent {
                background: #efefef;
                padding: 5px;
                border-radius: 3px;
                max-height: 100px;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}

// Colocada estilização fora pois o popover é injetado direto na body
.popoverContainer {
    display: flex;
    flex-direction: column;
}
